@import url("https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:wght@500&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;

    --ring: 215 20.2% 65.1%;

    --radius: 0.5rem;

    --accent-primary-dimmer: rgb(27, 59, 165);
    --accent-primary-default: rgba(0, 89, 236, 1);
    --accent-primary-higher: rgba(233, 244, 255, 1);

    --accent-positive-dimmer: rgba(38, 145, 65, 1);
    --accent-positive-default: rgba(40, 169, 72, 1);
    --accent-positive-higher: rgba(230, 252, 230, 1);

    --accent-negative-dimmer: rgba(234, 0, 29, 1);
    --accent-negative-default: rgba(252, 3, 54, 1);
    --accent-negative-higher: rgba(255, 232, 234, 1);

    --accent-warning-dimmer: rgba(255, 147, 1, 1);
    --accent-warning-default: rgba(255, 173, 2, 1);
    --accent-warning-higher: rgba(255, 244, 207, 1);

    --foreground-default: rgba(23, 23, 23, 1);
    --foreground-dimmer: rgba(102, 102, 102, 1);
    --foreground-dimmest: rgba(151, 151, 151, 1);

    --background-root: rgba(249, 249, 249, 1);
    --background-default: rgba(255, 255, 255, 1);
    --background-dimmer: rgba(242, 242, 242, 1);
    --background-dimmest: rgba(233, 233, 233, 1);
    --background-deepest: rgba(201, 201, 201, 1);
  }
}

.lds-ring-smaller {
  display: inline-block;
  position: relative;
  width: 30px;
  height: 30px;
}

.lds-ring-smaller div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 33px;
  height: 33px;
  margin: 3px;
  border: 3px solid #666;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #666 transparent transparent transparent;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 44px;
  height: 44px;
  margin: 4px;
  border: 4px solid #666;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #666 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
